import React from "react"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import styled from "styled-components"

const IconWrapper = styled.div`
  color: ${props =>
    props.red ? "#ec5d5d" : props.yellow ? "#fdff61" : "#58cb53f7"};
  font-size: 1.2rem;
`

export const IconCheck = () => (
  <IconWrapper green>
    <FaCheck title="Ja" />
  </IconWrapper>
)

export const IconCross = () => (
  <IconWrapper red>
    <FaTimes title="Nein" />
  </IconWrapper>
)
